import React from "react";
import "./RenderTrustBadge.css";

const RenderTrustBadge = () => {
  return (
    <div className="brandpush-trust-badge">
      <div className="brandpush-title-container">
        <span className="brandpush-title">AS SEEN ON</span>
      </div>
      <div className="brandpush-logo-container">
        <img
          className="brandpush-leaf"
          alt="Trust Reef"
          src="https://www.brandpush.co/cdn-cgi/imagedelivery/gKm6BYVdHCj_SVQET_Msrw/3fb10293-8878-4ce5-5496-cef376fe9300/public"
        />
        <img
          className="brandpush-leaf mirrored"
          alt="Trust Reef"
          src="https://www.brandpush.co/cdn-cgi/imagedelivery/gKm6BYVdHCj_SVQET_Msrw/3fb10293-8878-4ce5-5496-cef376fe9300/public"
        />
        <div className="brandpush-logo-container-item">
          <div className="brandpush-vertical-center">
            <a
              href="https://markets.businessinsider.com/news/stocks/bigloads-com-enhances-trailer-rental-experience-with-zero-fees-and-increased-local-exposure-1034191910"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                alt="Featured on Business Insider"
                className="brandpush-news-logo"
                src="https://www.brandpush.co/cdn-cgi/imagedelivery/gKm6BYVdHCj_SVQET_Msrw/ea272993-a87c-4e0e-48bd-6ca4d5b1ea00/public"
              />
            </a>
            <a
              href="https://apnews.com/press-release/marketersmedia/bigloads-com-enhances-trailer-rental-experience-with-zero-fees-and-increased-local-exposure-e4260863967959ab439a8a0dc6d9bff8"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                alt="Featured on Associated Press"
                className="brandpush-news-logo"
                src="https://www.brandpush.co/cdn-cgi/imagedelivery/gKm6BYVdHCj_SVQET_Msrw/882fcdbd-c12d-4730-abbb-e3e6c2951d00/public"
              />
            </a>
            <a
              href="https://www.theglobeandmail.com/investing/markets/markets-news/Plentisoft/30295965/bigloadscom-enhances-trailer-rental-experience-with-zero-fees-and-increased-local-exposure/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                alt="Featured on The Globe And Mail"
                className="brandpush-news-logo"
                src="https://www.brandpush.co/cdn-cgi/imagedelivery/gKm6BYVdHCj_SVQET_Msrw/7acfc7a6-2b16-4ba6-01d5-96b99d462f00/public"
              />
            </a>
          </div>
        </div>
      </div>
      <div className="brandpush-footer">
        <img
          className="brandpush-verified-icon"
          src="https://www.brandpush.co/cdn-cgi/imagedelivery/gKm6BYVdHCj_SVQET_Msrw/5e5b9b47-6288-4640-2b2a-cd2459605c00/public"
          alt="Verified"
        />
        <span>
          Verified by <a href="https://www.brandpush.co" target="_blank" rel="noopener noreferrer">BrandPush.co</a>
        </span>
      </div>
    </div>
  );
};

export default RenderTrustBadge;